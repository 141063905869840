import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  ArrowRight,
  X,
  Phone,
  Mail,
  MapPin,
  Check,
  Copy,
  ChevronUp,
  ChevronDown,
  Home,
  Key,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';

const PropertiesPage = () => {
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedType, setSelectedType] = useState('All');
  const [copiedEmail, setCopiedEmail] = useState(false);
  const [copiedPhone, setCopiedPhone] = useState(false);
  const location = useLocation();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  
  const contactInfo = {
    email: "info@novaml.co.uk",
    phone: "+44 (0)20 1234 5678"
  };

  const copyToClipboard = async (text, type) => {
    try {
      await navigator.clipboard.writeText(text);
      if (type === 'email') {
        setCopiedEmail(true);
        setTimeout(() => setCopiedEmail(false), 2000);
      } else {
        setCopiedPhone(true);
        setTimeout(() => setCopiedPhone(false), 2000);
      }
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };


  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 150);
      
      // Update active section based on scroll position
      const sections = document.querySelectorAll('section[id]');
      const scrollY = window.scrollY;

      sections.forEach(section => {
        const sectionTop = section.offsetTop - 100;
        const sectionHeight = section.offsetHeight;
        const sectionId = section.getAttribute('id');

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          setActiveSection(sectionId);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const triggerNotification = () => {
    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 3000);
  };


  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  
  useEffect(() => {
    const controlHeader = () => {
      const currentScrollY = window.scrollY;
  
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
      
      setLastScrollY(currentScrollY);
    };
  
    window.addEventListener('scroll', controlHeader);
    return () => window.removeEventListener('scroll', controlHeader);
  }, [lastScrollY]);


// Add ESC key handler for modal
useEffect(() => {
  const handleKeydown = (event) => {
    if (event.key === 'Escape') {
      closePropertyModal();
    } else if (event.key === 'ArrowLeft') {
      navigateImages('prev');
    } else if (event.key === 'ArrowRight') {
      navigateImages('next');
    }
  };

  // Only add the event listener if the modal is open
  if (selectedProperty) {
    window.addEventListener('keydown', handleKeydown);
    
    // Cleanup the event listener when the component unmounts or modal closes
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }
}, [selectedProperty]); // Dependency on selectedProperty ensures the listener is only active when modal is open
  

useEffect(() => {
  const searchParams = new URLSearchParams(location.search);
  const propertyTitle = searchParams.get('property');
  
  if (propertyTitle) {
    const propertyToShow = properties.find(p => p.title === propertyTitle);
    if (propertyToShow) {
      setSelectedProperty(propertyToShow);
      setActiveImageIndex(0);
    }
  }
}, [location]);


  const properties = [
                                     /* Residential Lettings */
    { 
      title: "The Glasshouse",
      type: "Residential",
      location: "Arthur Rd",
      desc: "Premium waterfront development featuring 124 luxury apartments with stunning river views",
      features: ["24/7 Concierge", "Gym & Spa", "Secure Parking", "Riverside Gardens"],
      img: "glasshouse/glasshouse.jpg",
      status: "Fully Managed",
      // Add new detailed information
      details: {
        size: "1,200 - 2,500 sq ft",
        units: "124 apartments",
        yearBuilt: "2020",
        parking: "Underground secure parking",
        amenities: [
          "24/7 Concierge Service",
          "Residents' Gym & Spa",
          "Private Gardens",
          "River Views",
          "Secure Parking",
          "Bike Storage",
          "CCTV Security",
          "Lift Access"
        ],
        specifications: [
          "High-end appliances",
          "Floor-to-ceiling windows",
          "Balconies/terraces",
          "Engineered hardwood flooring",
          "Smart home technology",
          "Underfloor heating"
        ],
        management: {
          type: "Full Management",
          services: [
            "Building Maintenance",
            "Security Management",
            "Cleaning Services",
            "Garden Maintenance",
            "Service Charge Collection"
          ]
        }
      },
      // Multiple images for gallery
      images: [
        "glasshouse/glasshouse.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 2.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 3.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 4.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 5.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 6.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 7.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 8.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 9.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 11.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 12.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 13.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 14.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 15.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 16.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 17.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 18.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27 19.jpg",
        "glasshouse/PHOTO-2024-11-27-20-55-27.jpg"
      ],
      // Contact information
      contact: {
        manager: "Sarah Thompson",
        phone: "+44 (0)20 1234 5678",
        email: "riverside@novaml.co.uk"
      }
    },
    {
      title: "Toynbee House",
      type: "Residential",
      location: "Toynbee Street",
      desc: "Modern office complex with retail space, featuring state-of-the-art facilities and excellent transport links",
      features: ["24/7 Security", "Meeting Rooms", "Parking", "On-site Café"],
      img: "toynbee/toynbee.jpg",
      status: "Luxury Lettings",
            // Add new detailed information
            details: {
              size: "",
              units: "",
              yearBuilt: "",
              parking: "",
              amenities: [
              ],
              specifications: [
              ],
              management: {
                type: "",
                services: [
                ]
              }
            },
            // Multiple images for gallery
            images: [
              "toynbee/toynbee.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-16 2.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-16 3.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-16 4.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-16 5.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-16.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 2.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 3.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 4.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 5.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 6.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 7.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 8.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 9.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 10.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 11.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 12.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 13.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 14.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 15.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 16.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 17.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 18.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 19.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17 20.jpg",
              "toynbee/PHOTO-2024-11-27-20-55-17.jpg"
            ],
            // Contact information
            contact: {
              manager: "",
              phone: "",
              email: ""
            }
    },
                                     /* Short Term Luxury */
    {
      title: "Madinah Mansions",
      type: "Residential",
      location: "Vauxhall Bridge Rd",
      desc: "Modern office complex with retail space, featuring state-of-the-art facilities and excellent transport links",
      features: ["24/7 Security", "Meeting Rooms", "Parking", "On-site Café"],
      img: "madinah-mansions/madinah-mansions.jpg",
      status: "Luxury Lettings",
            // Add new detailed information
            details: {
              size: "",
              units: "",
              yearBuilt: "",
              parking: "",
              amenities: [
              ],
              specifications: [
              ],
              management: {
                type: "",
                services: [
                ]
              }
            },
            // Multiple images for gallery
            images: [
              "madinah-mansions/madinah-mansions.jpg",
              "madinah-mansions/PHOTO-2024-11-27-20-46-28 2.jpg",
              "madinah-mansions/PHOTO-2024-11-27-20-46-28 3.jpg",
              "madinah-mansions/PHOTO-2024-11-27-20-46-28 4.jpg",
              "madinah-mansions/PHOTO-2024-11-27-20-46-28 5.jpg",
              "madinah-mansions/PHOTO-2024-11-27-20-46-28 6.jpg",
              "madinah-mansions/PHOTO-2024-11-27-20-46-28 7.jpg",
              "madinah-mansions/PHOTO-2024-11-27-20-46-28 8.jpg",
              "madinah-mansions/PHOTO-2024-11-27-20-46-28 9.jpg",
              "madinah-mansions/PHOTO-2024-11-27-20-46-28.jpg",
              "madinah-mansions/PHOTO-2024-11-27-20-46-29 2.jpg",
              "madinah-mansions/PHOTO-2024-11-27-20-46-29 3.jpg",
              "madinah-mansions/PHOTO-2024-11-27-20-46-29 4.jpg",
              "madinah-mansions/PHOTO-2024-11-27-20-46-29.jpg"

            ],
            // Contact information
            contact: {
              manager: "",
              phone: "",
              email: ""
            }
    },
    {
      title: "Haldane House",
      type: "Residential",
      location: "Haldane Road",
      desc: "Modern office complex with retail space, featuring state-of-the-art facilities and excellent transport links",
      features: ["24/7 Security", "Meeting Rooms", "Parking", "On-site Café"],
      img: "haldane/haldane.jpg",
      status: "Luxury Lettings",
            // Add new detailed information
            details: {
              size: "",
              units: "",
              yearBuilt: "",
              parking: "",
              amenities: [
              ],
              specifications: [
              ],
              management: {
                type: "",
                services: [
                ]
              }
            },
            // Multiple images for gallery
            images: [
              "haldane/haldane.jpg",
              "haldane/PHOTO-2024-11-27-20-43-18 2.jpg",
              "haldane/PHOTO-2024-11-27-20-43-18 3.jpg",
              "haldane/PHOTO-2024-11-27-20-43-18.jpg",
              "haldane/PHOTO-2024-11-27-20-43-19 2.jpg",
              "haldane/PHOTO-2024-11-27-20-43-19 3.jpg",
              "haldane/PHOTO-2024-11-27-20-43-19.jpg"
            ],
            // Contact information
            contact: {
              manager: "",
              phone: "",
              email: ""
            }
    },
    {
      title: "Al Hamra Mansions",
      type: "Residential",
      location: "Mitcham Road",
      desc: "Modern office complex with retail space, featuring state-of-the-art facilities and excellent transport links",
      features: ["24/7 Security", "Meeting Rooms", "Parking", "On-site Café"],
      img: "al-hamra-mansions/al-hamra-mansions.jpg",
      status: "Luxury Lettings",
            // Add new detailed information
            details: {
              size: "",
              units: "",
              yearBuilt: "",
              parking: "",
              amenities: [
              ],
              specifications: [
              ],
              management: {
                type: "",
                services: [
                ]
              }
            },
            // Multiple images for gallery
            images: [
              "al-hamra-mansions/al-hamra-mansions.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-03 2.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-03 3.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-03 4.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-03 5.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-03 6.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-03 7.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-03 9.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-03 10.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-03 11.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-03 12.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-03 13.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-03 14.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-03.jpg",
              "al-hamra-mansions/PHOTO-2024-11-27-20-58-04.jpg"
            ],
            // Contact information
            contact: {
              manager: "",
              phone: "",
              email: ""
            }
    }
    /* {
      title: "Heritage Court Development",
      type: "Mixed Use",
      location: "Birmingham",
      desc: "Historic conversion featuring luxury apartments and boutique retail spaces in the heart of the city",
      features: ["Period Features", "Courtyard Garden", "Secure Entry", "Retail Space"],
      img: "historic-lux.webp",
      status: "Full Management"
    } */
  ];

  const filteredProperties = selectedType === 'All' 
    ? properties 
    : properties.filter(property => property.type === selectedType);


  // Function to handle modal opening
  const openPropertyModal = (property) => {
    setSelectedProperty(property);
    setActiveImageIndex(0);
    document.body.style.overflow = 'hidden';
  };

  // Function to handle modal closing
  const closePropertyModal = () => {
    setSelectedProperty(null);
    setActiveImageIndex(0);
    document.body.style.overflow = 'unset';
  };

  // Function to navigate through images
  const navigateImages = (direction) => {
    if (!selectedProperty) return;
    
    if (direction === 'next') {
      setActiveImageIndex((prev) => 
        prev === selectedProperty.images.length - 1 ? 0 : prev + 1
      );
    } else {
      setActiveImageIndex((prev) => 
        prev === 0 ? selectedProperty.images.length - 1 : prev - 1
      );
    }
  };




  return (
    <div className="min-h-screen bg-neutral-50">
      {/* Notification */}
      <div className={`fixed top-4 right-4 transform transition-all duration-300 z-50 ${showNotification ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'}`}>
        <div className="bg-[#b2a5a1] text-white px-6 py-3 rounded-lg shadow-lg flex items-center">
          <span>Thanks for your interest! We'll be in touch soon.</span>
          <button onClick={() => setShowNotification(false)} className="ml-3 hover:text-neutral-200">
            <X size={18} />
          </button>
        </div>
      </div>

      {/* Scroll to top button */}
      <button
        onClick={scrollToTop}
        className={`fixed bottom-8 right-8 z-40 p-3 bg-[#b2a5a1] text-white rounded-full shadow-lg transition-all duration-300 hover:bg-[#8c7f7c] ${
          showScrollTop ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-16'
        }`}
      >
        <ChevronUp size={24} />
      </button>

{/* Header */}
<header 
  className={`
    bg-white border-b border-[#eae7e6] sticky top-0 z-40 
    transition-transform duration-300 
    ${isHidden ? '-translate-y-full' : 'translate-y-0'}
  `}
>    
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="flex justify-between items-center h-20">
      {/* Logo */}
      <div className="w-64"> 
        <Link to="/" className="flex items-center group cursor-pointer">
          <img 
            src="/header-logo-new.png" 
            alt="Nova Asset Management" 
            className="h-12 md:h-14 w-auto object-contain md:object-fill transform group-hover:scale-105 transition-transform duration-200"
          />
        </Link>
      </div>

      {/* Centered Desktop Navigation */}
      <nav className="hidden md:flex flex-1 justify-center">
        <div className="flex space-x-12">
          {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
            <div
              key={item}
              className="relative group"
              onMouseEnter={() => {
                setHoveredLink(item);
                if (item === "Services") setOpenDropdown(true);
              }}
              onMouseLeave={() => {
                setHoveredLink(null);
                if (item === "Services") setOpenDropdown(false);
              }}
            >
              <Link
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                className={`
                  relative text-[#8c7f7c] hover:text-[#b2a5a1] font-medium 
                  transition-colors duration-200 py-2 flex items-center 
                  ${(item === "Home" && location.pathname === "/") || 
                    (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                      ? "text-[#b2a5a1]" 
                      : ""
                  }
                `}
              >
                {item}
                {item === "Services" && (
                  <ChevronDown className="ml-1 w-4 h-4" />
                )}
                <span 
                  className={`
                    absolute bottom-0 left-0 w-full h-0.5 bg-[#b2a5a1] 
                    transform origin-left transition-transform duration-200 
                    ${((item === "Home" && location.pathname === "/") || 
                       (item !== "Home" && location.pathname === `/${item.toLowerCase()}`) ||
                       hoveredLink === item) ? 'scale-x-100' : 'scale-x-0'
                    }
                  `}
                />
              </Link>

              {/* Services Dropdown */}
              {item === "Services" && (
                <div className={`
                  absolute left-1/2 transform -translate-x-1/2 mt-2 w-72 
                  bg-white rounded-lg shadow-lg border border-[#eae7e6] 
                  transition-all duration-200 
                  ${openDropdown ? 'opacity-100 visible translate-y-0' : 'opacity-0 invisible -translate-y-2'}
                `}>
                  <div className="p-4 space-y-3">
                    {[
                      { 
                        icon: Home, 
                        title: "Property Management",
                        sectionId: "property-management", 
                        subItems: [
                          { name: "Residential Lettings", id: "residential-lettings" },
                          { name: "Short Term Luxury Lettings", id: "short-term-luxury-lettings" },
                          { name: "Portfolio Management", id: "portfolio-management" }
                        ] 
                      },
                      { 
                        icon: Key, 
                        title: "Planning, Design & Build",
                        sectionId: "development", 
                        subItems: [
                          { name: "Planning", id: "planning" },
                          { name: "Design and Build", id: "design-and-build" }
                        ] 
                      }
                    ].map((service, index) => (
                      <div
                        key={index}
                        className="relative group/item"
                        onMouseEnter={() => setHoveredLink(service.title)}
                        onMouseLeave={() => setHoveredLink(null)}
                      >
                        <div 
                          className="flex items-center justify-between px-3 py-2 rounded-md hover:bg-[#f7f5f5] text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 cursor-pointer"
                          onClick={() => {
                            navigate(`/services#${service.sectionId}`);
                            setOpenDropdown(false);
                            setHoveredLink(null);
                          }}
                        >
                          <div className="flex items-center space-x-3 min-w-0">
                            <service.icon className="w-5 h-5 flex-shrink-0" />
                            <span className="font-medium whitespace-nowrap">{service.title}</span>
                          </div>
                          <ChevronDown className="w-4 h-4 flex-shrink-0 ml-3" />
                        </div>

                        {/* Nested Dropdown */}
                        <div className={`
                          absolute left-full top-0 w-56 bg-white rounded-lg 
                          shadow-lg border border-[#eae7e6] ml-2 transition-all duration-200
                          ${hoveredLink === service.title ? 'opacity-100 visible translate-x-0' : 'opacity-0 invisible -translate-x-2'}
                        `}>
                          <div className="py-2">
                            {service.subItems.map((subItem, subIndex) => (
                              <button
                                key={subIndex}
                                onClick={() => {
                                  navigate(`/services#${subItem.id}`);
                                  setOpenDropdown(false);
                                  setHoveredLink(null);
                                }}
                                className="w-full text-left px-4 py-2 text-[#8c7f7c] hover:text-[#b2a5a1] hover:bg-[#f7f5f5] text-sm transition-colors duration-200"
                              >
                                {subItem.name}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </nav>

      {/* Right Section - Contact Icons and Mobile Menu */}
      <div className="w-64 flex items-center justify-end">
        {/* Contact Icons - Desktop Only */}
        <div className="hidden md:flex items-center gap-4 mr-4">
          {/* Email Icon with Tooltip */}
          <div className="relative">
            <div className="p-2 group">
              <Mail 
                size={20} 
                className="text-[#8c7f7c] group-hover:text-[#b2a5a1] cursor-pointer"
              />
              {/* Email Tooltip */}
              <div className="absolute right-0 mt-2 w-auto min-w-max invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 bg-white border border-[#eae7e6] rounded-lg shadow-lg p-3 z-50">
                <div className="flex items-center space-x-2 whitespace-nowrap">
                  <span className="text-[#8c7f7c]">{contactInfo.email}</span>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(contactInfo.email, 'email');
                    }}
                    className="text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 ml-2"
                  >
                    {copiedEmail ? <Check size={16} /> : <Copy size={16} />}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Phone Icon with Tooltip */}
          <div className="relative">
            <div className="p-2 group">
              <Phone 
                size={20} 
                className="text-[#8c7f7c] group-hover:text-[#b2a5a1] cursor-pointer"
              />
              {/* Phone Tooltip */}
              <div className="absolute right-0 mt-2 w-auto min-w-max invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 bg-white border border-[#eae7e6] rounded-lg shadow-lg p-3 z-50">
                <div className="flex items-center space-x-2 whitespace-nowrap">
                  <span className="text-[#8c7f7c]">{contactInfo.phone}</span>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(contactInfo.phone, 'phone');
                    }}
                    className="text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 ml-2"
                  >
                    {copiedPhone ? <Check size={16} /> : <Copy size={16} />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile menu button */}
        <button 
          className="md:hidden relative group p-2"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div className="flex flex-col space-y-1.5">
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transition-all duration-200 ${isMenuOpen ? 'opacity-0' : ''}`}></span>
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
          </div>
        </button>
      </div>
    </div>
  </div>

{/* Mobile Navigation */}
<div className={`md:hidden bg-white overflow-hidden transition-all duration-300 ${isMenuOpen ? 'max-h-screen' : 'max-h-0'}`}>
  <div className="px-4 py-3 space-y-2">
    {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
      <div key={item}>
        {item === "Services" ? (
          <div>
            <button
              className={`w-full px-4 py-3 text-center hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 ${
                location.pathname === "/services" ? "text-[#b2a5a1] bg-[#f7f5f5]" : "text-[#8c7f7c]"
              }`}
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              <div className="flex items-center justify-center">
                Services
                <ChevronDown 
                  className={`ml-1 w-4 h-4 transform transition-transform duration-200 ${
                    openDropdown ? 'rotate-180' : ''
                  }`} 
                />
              </div>
            </button>
            
            {/* Mobile Services Dropdown */}
            <div className={`overflow-hidden transition-all duration-200 ${openDropdown ? 'max-h-96' : 'max-h-0'}`}>
              <div className="py-2 px-4 space-y-2">
                {[
                  { 
                    icon: Home, 
                    title: "Property Management",
                    sectionId: "property-management",
                    subItems: [
                      { name: "Residential Lettings", id: "residential-lettings" },
                      { name: "Short Term Luxury Lettings", id: "short-term-luxury-lettings" },
                      { name: "Portfolio Management", id: "portfolio-management" }
                    ]
                  },
                  { 
                    icon: Key, 
                    title: "Planning, Design & Build",
                    sectionId: "development",
                    subItems: [
                      { name: "Planning", id: "planning" },
                      { name: "Design and Build", id: "design-and-build" }
                    ]
                  }
                ].map((service, index) => (
                  <div key={index} className="space-y-2">
                    <button
                      onClick={() => {
                        navigate(`/services#${service.sectionId}`);
                        setIsMenuOpen(false);
                        setOpenDropdown(false);
                      }}
                      className="w-full flex items-center justify-between px-4 py-2 rounded-md hover:bg-[#f7f5f5] text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200"
                    >
                      <div className="flex items-center space-x-3">
                        <service.icon className="w-5 h-5" />
                        <span className="font-medium">{service.title}</span>
                      </div>
                    </button>
                      
                    {/* Sub-items */}
                    <div className="pl-11 space-y-1">
                      {service.subItems.map((subItem, subIndex) => (
                        <button
                          key={subIndex}
                          onClick={() => {
                            navigate(`/services#${subItem.id}`);
                            setIsMenuOpen(false);
                            setOpenDropdown(false);
                          }}
                          className="w-full text-left py-2 px-4 text-[#8c7f7c] hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-md text-sm transition-colors duration-200"
                        >
                          {subItem.name}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          ) : (
            <Link
              to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
              className={`block px-4 py-3 text-center hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 ${
                (item === "Home" && location.pathname === "/") || 
                (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                  ? "text-[#b2a5a1] bg-[#f7f5f5]"
                  : "text-[#8c7f7c]"
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="flex items-center justify-center">
                {item}
              </div>
            </Link>
          )}
        </div>
      ))}
    </div>
  </div>
</header>

      {/* Main Content */}
      <div className="bg-gradient-to-br from-[#8c7f7c]/70 to-[#6a5f5c]/70 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          {/* Page Title */}
          <div className="text-center mb-16">
            <h1 className="text-4xl font-bold text-white sm:text-5xl">
              Our Properties</h1>
            <p className="mt-4 text-xl text-neutral-200">
              Discover our portfolio of expertly managed properties
            </p>
          </div>

          {/* Type Filter */}
          <div className="flex justify-center gap-4 mb-12">
            {['All', 'Residential', 'Commercial', 'Mixed Use'].map((type) => (
              <button
                key={type}
                onClick={() => setSelectedType(type)}
                className={`px-6 py-2 rounded-full text-sm font-medium transition-all duration-200 
                  ${selectedType === type 
                    ? 'bg-white text-[#8c7f7c]' 
                    : 'bg-white/10 text-white hover:bg-white/20'}`}
              >
                {type}
              </button>
            ))}
          </div>

          {/* Properties Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
  {filteredProperties.map((property, i) => (
    <div 
      key={i}
      className="bg-white/10 backdrop-blur-lg rounded-xl shadow-md border border-white/20 overflow-hidden hover:shadow-xl transition-all duration-300"
    >
      {/* Updated Image Container */}
      <div className="relative">
        <div className="relative w-full pt-[75%] overflow-hidden">
          <img 
            src={property.img}
            alt={property.title}
            className="absolute inset-0 w-full h-full object-cover object-center"
          />
        </div>
        {/* Property Type Badge */}
        <div className="absolute top-4 left-4">
          <span className="px-3 py-1 bg-white/90 backdrop-blur-sm text-[#8c7f7c] text-sm font-medium rounded-full">
            {property.type}
          </span>
        </div>
        {/* Status Badge */}
        <div className="absolute top-4 right-4">
          <span className="px-3 py-1 bg-[#b2a5a1]/90 backdrop-blur-sm text-white text-sm font-medium rounded-full">
            {property.status}
          </span>
        </div>
      </div>

                {/* Content */}
                <div className="p-6">
                  <h3 className="text-xl font-semibold text-white mb-2">
                    {property.title}
                  </h3>
                  <p className="text-neutral-200 flex items-center mb-4">
                    <MapPin className="h-4 w-4 mr-1" />
                    {property.location}
                  </p>
                  <p className="text-neutral-200 mb-6">{property.desc}</p>

                  {/* Features */}
                  <div className="mb-6">
                    <div className="flex flex-wrap gap-2">
                      {property.features.map((feature, index) => (
                        <span 
                          key={index}
                          className="px-3 py-1 bg-white/10 text-neutral-200 text-sm rounded-full"
                        >
                          {feature}
                        </span>
                      ))}
                    </div>
                  </div>

                  {/* Action Button */}
                  <button
                    onClick={() => openPropertyModal(property)}
                    className="w-full px-4 py-2 bg-white text-[#8c7f7c] text-sm font-medium rounded-lg hover:bg-white/90 transition-colors duration-200 flex items-center justify-center group"
                  >
                    View Details
                    <ArrowRight className="ml-2 h-4 w-4 transform group-hover:translate-x-1 transition-transform" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

{/* Property Details Modal */}
{selectedProperty && (
  <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 overflow-y-auto">
    <div className="min-h-screen px-4 py-12 flex items-center justify-center">
      <div className="bg-white rounded-xl w-full max-w-6xl h-[650px] relative">
        {/* Close button */}
        <button
          onClick={closePropertyModal}
          className="absolute top-4 right-4 p-2 hover:bg-neutral-100 rounded-full z-10"
        >
          <X className="h-6 w-6 text-neutral-500" />
        </button>

        <div className="grid grid-cols-1 lg:grid-cols-2 h-full">
          {/* Image Gallery - Fixed height */}
          <div className="relative h-[300px] lg:h-full">
            <div className="absolute inset-0">
              <img
                src={selectedProperty.images?.[activeImageIndex] || selectedProperty.img}
                alt={selectedProperty.title}
                className="w-full h-full object-cover object-center rounded-t-xl lg:rounded-l-xl lg:rounded-tr-none"
              />
            </div>
            
            {/* Image navigation */}
            {selectedProperty.images?.length > 1 && (
              <div className="absolute inset-x-0 bottom-0 flex justify-between p-4">
                <button
                  onClick={() => navigateImages('prev')}
                  className="p-2 bg-white/80 rounded-full hover:bg-white transition-colors"
                >
                  <ChevronLeft className="h-6 w-6" />
                </button>
                <button
                  onClick={() => navigateImages('next')}
                  className="p-2 bg-white/80 rounded-full hover:bg-white transition-colors"
                >
                  <ChevronRight className="h-6 w-6" />
                </button>
              </div>
            )}

            {/* Image counter */}
            {selectedProperty.images?.length > 1 && (
              <div className="absolute top-4 left-4 px-3 py-1 bg-black/50 text-white rounded-full text-sm">
                {activeImageIndex + 1} / {selectedProperty.images.length}
              </div>
            )}
          </div>

          {/* Property Details - Scrollable content */}
          <div className="relative h-[500px] lg:h-full">
            <div className="absolute inset-0 overflow-y-auto">
              <div className="p-8">
                {/* Header Section - Sticky */}
                <div className="flex items-start justify-between mb-6">
                  <div>
                    <h2 className="text-2xl font-bold text-[#4f3b3a]">
                      {selectedProperty.title}
                    </h2>
                    <p className="text-[#8c7f7c] flex items-center mt-2">
                      <MapPin className="h-5 w-5 mr-2" />
                      {selectedProperty.location}
                    </p>
                  </div>
                  <span className="px-3 py-1 bg-[#b2a5a1] text-white rounded-full text-sm">
                    {selectedProperty.status}
                  </span>
                </div>

                {/* Description */}
                <div className="mb-8">
                  <h3 className="text-lg font-semibold text-[#4f3b3a] mb-3">Description</h3>
                  <p className="text-[#8c7f7c]">{selectedProperty.desc}</p>
                </div>

                {/* Features */}
                <div className="mb-8">
                  <h3 className="text-lg font-semibold text-[#4f3b3a] mb-3">Features</h3>
                  <div className="grid grid-cols-2 gap-4">
                    {selectedProperty.features.map((feature, index) => (
                      <div key={index} className="flex items-center text-[#8c7f7c]">
                        <Check className="h-5 w-5 mr-2 text-[#b2a5a1]" />
                        {feature}
                      </div>
                    ))}
                  </div>
                </div>

                {/* Additional Details */}
                {selectedProperty.details && (
                  <div className="mb-8">
                    <h3 className="text-lg font-semibold text-[#4f3b3a] mb-3">Property Details</h3>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="text-[#8c7f7c]">
                        <span className="font-medium">Size:</span> {selectedProperty.details.size}
                      </div>
                      <div className="text-[#8c7f7c]">
                        <span className="font-medium">Units:</span> {selectedProperty.details.units}
                      </div>
                      <div className="text-[#8c7f7c]">
                        <span className="font-medium">Built:</span> {selectedProperty.details.yearBuilt}
                      </div>
                      <div className="text-[#8c7f7c]">
                        <span className="font-medium">Parking:</span> {selectedProperty.details.parking}
                      </div>
                    </div>
                  </div>
                )}

                {/* Contact Information */}
                {selectedProperty.contact && (
                  <div className="border-t border-neutral-200 pt-8">
                    <h3 className="text-lg font-semibold text-[#4f3b3a] mb-4">Contact Property Manager</h3>
                    <div className="space-y-4">
                      <p className="text-[#8c7f7c] font-medium">{selectedProperty.contact.manager}</p>
                      <div className="flex items-center space-x-4">
                        <a
                          href={`tel:${selectedProperty.contact.phone}`}
                          className="flex items-center px-4 py-2 bg-[#f7f5f5] text-[#8c7f7c] rounded-lg hover:bg-[#eae7e6] transition-colors"
                        >
                          <Phone className="h-5 w-5 mr-2" />
                          {selectedProperty.contact.phone}
                        </a>
                        <a
                          href={`mailto:${selectedProperty.contact.email}`}
                          className="flex items-center px-4 py-2 bg-[#f7f5f5] text-[#8c7f7c] rounded-lg hover:bg-[#eae7e6] transition-colors"
                        >
                          <Mail className="h-5 w-5 mr-2" />
                          {selectedProperty.contact.email}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)}


{/* Contact */}
<div className="bg-[#2c2827]">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            {[
              { icon: Phone, text: "+44 (0)20 1234 5678" },
              { icon: Mail, text: "info@novaml.co.uk" },
              { icon: MapPin, text: "123 Property Street, London, UK" },
            ].map((item, i) => (
              <button
                key={i}
                onClick={() => triggerNotification()}
                className="flex items-center p-4 bg-[#3d3533] rounded-lg shadow-sm border border-[#4d4341] hover:border-[#b2a5a1] hover:shadow-md transition-all duration-200 group"
              >
                <item.icon className="h-6 w-6 text-[#b2a5a1] group-hover:scale-110 transition-transform" />
                <span className="ml-3 text-[#968a87] group-hover:text-[#b2a5a1] transition-colors duration-200">{item.text}</span>
              </button>
            ))}
          </div>
        </div>
      </div>

{/* Footer */}
<footer className="bg-[#2c2827] border-t border-[#3d3533]">
  <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
      {[
        { 
          title: "Company", 
          links: [
            { name: "Home", path: "/" },
            { name: "About", path: "/about" },
            { name: "Services", path: "/services" },
            { name: "Contact", path: "/contact" }
          ]
        },
        { 
          title: "Services", 
          links: [
            { name: "Property Management", sectionId: "property-management" },
            { name: "Planning, Design & Build", sectionId: "development" }
          ]
        },
        { 
          title: "Legal", 
          links: [
            { name: "Privacy", path: "/Registration Certificate.pdf", type: "pdf" },
            { name: "Data Protection", path: "/Registration Certificate.pdf", type: "pdf" },
            { name: "Terms", path: "/terms" }
          ]
        },
        { 
          title: "Social", 
          links: [
            { name: "LinkedIn", path: "https://linkedin.com" },
            { name: "Twitter", path: "https://twitter.com" },
            { name: "Facebook", path: "https://facebook.com" }
          ]
        }
      ].map((section, i) => (
        <div key={i}>
          <h3 className="text-sm font-semibold text-[#b2a5a1] tracking-wider uppercase">
            {section.title}
          </h3>
          <ul className="mt-4 space-y-4">
            {section.links.map((link, j) => (
              <li key={j}>
                {section.title === "Social" ? (
                  <a 
                    href={link.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </a>
                ) : section.title === "Services" ? (
                  <button
                    onClick={() => navigate(`/services#${link.sectionId}`)}
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </button>
                ) : link.type === "pdf" ? (
                  <a
                    href={link.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </a>
                ) : (
                  <Link
                    to={link.path}
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
    <div className="mt-8 border-t border-[#3d3533] pt-8">
      <p className="text-base text-[#968a87]">
        &copy; 2024 Nova Asset Management Ltd. All rights reserved.
      </p>
    </div>
  </div>
</footer>
    </div>
  );
};

export default PropertiesPage;