import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Home,
  Key,
  Building2, 
  Award, 
  UserCheck, 
  ArrowRight, 
  Users,
  Target, 
  Sparkles, 
  HandshakeIcon, 
  X, 
  PoundSterling,
  Laptop, 
  Headset, 
  MessageSquare, 
  Settings, 
  Phone, 
  Mail, 
  MapPin,
  ChevronUp,
  ChevronDown,
  Clipboard,
  BarChart3, 
  GitCommit, 
  Check, 
  Copy
} from 'lucide-react';

const AboutPage = () => {
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [copiedEmail, setCopiedEmail] = useState(false);
  const [copiedPhone, setCopiedPhone] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  
  const location = useLocation();

  // Header hide on scroll down, show on scroll up
  useEffect(() => {
    const controlHeader = () => {
      const currentScrollY = window.scrollY;
  
      if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
      
      setLastScrollY(currentScrollY);
    };
  
    window.addEventListener('scroll', controlHeader);
    return () => window.removeEventListener('scroll', controlHeader);
  }, [lastScrollY]);
  

  // Header Contact email & phone //
  const contactInfo = {
    email: "info@novaml.co.uk",
    phone: "+44 (0)20 1234 5678"
  };

  const copyToClipboard = async (text, type) => {
    try {
      await navigator.clipboard.writeText(text);
      if (type === 'email') {
        setCopiedEmail(true);
        setTimeout(() => setCopiedEmail(false), 2000);
      } else {
        setCopiedPhone(true);
        setTimeout(() => setCopiedPhone(false), 2000);
      }
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };


  // Handling Scroll Events //
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 150);
      
      // Update active section based on scroll position
      const sections = document.querySelectorAll('section[id]');
      const scrollY = window.scrollY;

      sections.forEach(section => {
        const sectionTop = section.offsetTop - 100;
        const sectionHeight = section.offsetHeight;
        const sectionId = section.getAttribute('id');

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          setActiveSection(sectionId);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  // Trigger Notification
  const triggerNotification = (message = "Thanks for your interest! We'll be in touch soon.") => {
    setShowNotification(message);
    setTimeout(() => setShowNotification(false), 3000);
  };
  

  // Section Navigation items
  const navItems = [
    { name: "Process", section: "process" },
    { name: "Overview", section: "overview" },
    { name: "Why Us", section: "whyus" },
    { name: "Values", section: "values" },
    { name: "Contact", section: "contact" }
  ];
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false);
  };


  return (
    <div className="min-h-screen bg-neutral-50">
      {/* Trigger Notification */}
      {showNotification && (
        <div className="fixed top-4 right-4 z-50 animate-slide-in-right">
          <div className="bg-[#b2a5a1] text-white px-6 py-3 rounded-lg shadow-lg flex items-center">
            <span>{showNotification}</span>
            <button onClick={() => setShowNotification(false)} className="ml-3 hover:text-neutral-200">
              <X size={18} />
            </button>
          </div>
        </div>
      )}

      {/* Scroll to top button (bottom right) */}
      <button
        onClick={scrollToTop}
        className={`fixed bottom-8 right-8 z-40 p-3 bg-[#b2a5a1] text-white rounded-full shadow-lg transition-all duration-300 hover:bg-[#8c7f7c] ${
          showScrollTop ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-16'
        }`}
      >
        <ChevronUp size={24} />
      </button>

      {/* Section Navigation items */}
      <div className="hidden lg:block fixed left-8 top-1/2 transform -translate-y-1/2 z-40">
        <div className="space-y-4">
          {navItems.map((item) => (
            <button
              key={item.section}
              onClick={() => scrollToSection(item.section)}
              className={`flex items-center space-x-2 text-sm ${
                activeSection === item.section ? 'text-black font-medium' : 'text-neutral-600'
              } hover:text-black transition-colors duration-200`}
            >
              <div className={`h-0.5 transition-all duration-200 ${
                activeSection === item.section ? 'w-12 bg-white' : 'w-8 bg-white/60 group-hover:w-12 group-hover:bg-white'
              }`} />
              <span className="font-medium">{item.name}</span>
            </button>
          ))}
        </div>
      </div>

{/* Header */}
<header 
  className={`
    bg-white border-b border-[#eae7e6] sticky top-0 z-40 
    transition-transform duration-300 
    ${isHidden ? '-translate-y-full' : 'translate-y-0'}
  `}
>    
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="flex justify-between items-center h-20">
      {/* Logo */}
      <div className="w-64"> 
        <Link to="/" className="flex items-center group cursor-pointer">
          <img 
            src="/header-logo-new.png" 
            alt="Nova Asset Management" 
            className="h-12 md:h-14 w-auto object-contain md:object-fill transform group-hover:scale-105 transition-transform duration-200"
          />
        </Link>
      </div>

      {/* Centered Desktop Navigation */}
      <nav className="hidden md:flex flex-1 justify-center">
        <div className="flex space-x-12">
          {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
            <div
              key={item}
              className="relative group"
              onMouseEnter={() => {
                setHoveredLink(item);
                if (item === "Services") setOpenDropdown(true);
              }}
              onMouseLeave={() => {
                setHoveredLink(null);
                if (item === "Services") setOpenDropdown(false);
              }}
            >
              <Link
                to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                className={`
                  relative text-[#8c7f7c] hover:text-[#b2a5a1] font-medium 
                  transition-colors duration-200 py-2 flex items-center 
                  ${(item === "Home" && location.pathname === "/") || 
                    (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                      ? "text-[#b2a5a1]" 
                      : ""
                  }
                `}
              >
                {item}
                {item === "Services" && (
                  <ChevronDown className="ml-1 w-4 h-4" />
                )}
                <span 
                  className={`
                    absolute bottom-0 left-0 w-full h-0.5 bg-[#b2a5a1] 
                    transform origin-left transition-transform duration-200 
                    ${((item === "Home" && location.pathname === "/") || 
                       (item !== "Home" && location.pathname === `/${item.toLowerCase()}`) ||
                       hoveredLink === item) ? 'scale-x-100' : 'scale-x-0'
                    }
                  `}
                />
              </Link>

              {/* Services Dropdown */}
              {item === "Services" && (
                <div className={`
                  absolute left-1/2 transform -translate-x-1/2 mt-2 w-72 
                  bg-white rounded-lg shadow-lg border border-[#eae7e6] 
                  transition-all duration-200 
                  ${openDropdown ? 'opacity-100 visible translate-y-0' : 'opacity-0 invisible -translate-y-2'}
                `}>
                  <div className="p-4 space-y-3">
                    {[
                      { 
                        icon: Home, 
                        title: "Property Management",
                        sectionId: "property-management", 
                        subItems: [
                          { name: "Residential Lettings", id: "residential-lettings" },
                          { name: "Short Term Luxury Lettings", id: "short-term-luxury-lettings" },
                          { name: "Portfolio Management", id: "portfolio-management" }
                        ] 
                      },
                      { 
                        icon: Key, 
                        title: "Planning, Design & Build",
                        sectionId: "development", 
                        subItems: [
                          { name: "Planning", id: "planning" },
                          { name: "Design and Build", id: "design-and-build" }
                        ] 
                      }
                    ].map((service, index) => (
                      <div
                        key={index}
                        className="relative group/item"
                        onMouseEnter={() => setHoveredLink(service.title)}
                        onMouseLeave={() => setHoveredLink(null)}
                      >
                        <div 
                          className="flex items-center justify-between px-3 py-2 rounded-md hover:bg-[#f7f5f5] text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 cursor-pointer"
                          onClick={() => {
                            navigate(`/services#${service.sectionId}`);
                            setOpenDropdown(false);
                            setHoveredLink(null);
                          }}
                        >
                          <div className="flex items-center space-x-3 min-w-0">
                            <service.icon className="w-5 h-5 flex-shrink-0" />
                            <span className="font-medium whitespace-nowrap">{service.title}</span>
                          </div>
                          <ChevronDown className="w-4 h-4 flex-shrink-0 ml-3" />
                        </div>

                        {/* Nested Dropdown */}
                        <div className={`
                          absolute left-full top-0 w-56 bg-white rounded-lg 
                          shadow-lg border border-[#eae7e6] ml-2 transition-all duration-200
                          ${hoveredLink === service.title ? 'opacity-100 visible translate-x-0' : 'opacity-0 invisible -translate-x-2'}
                        `}>
                          <div className="py-2">
                            {service.subItems.map((subItem, subIndex) => (
                              <button
                                key={subIndex}
                                onClick={() => {
                                  navigate(`/services#${subItem.id}`);
                                  setOpenDropdown(false);
                                  setHoveredLink(null);
                                }}
                                className="w-full text-left px-4 py-2 text-[#8c7f7c] hover:text-[#b2a5a1] hover:bg-[#f7f5f5] text-sm transition-colors duration-200"
                              >
                                {subItem.name}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </nav>

      {/* Right Section - Contact Icons and Mobile Menu */}
      <div className="w-64 flex items-center justify-end">
        {/* Contact Icons - Desktop Only */}
        <div className="hidden md:flex items-center gap-4 mr-4">
          {/* Email Icon with Tooltip */}
          <div className="relative">
            <div className="p-2 group">
              <Mail 
                size={20} 
                className="text-[#8c7f7c] group-hover:text-[#b2a5a1] cursor-pointer"
              />
              {/* Email Tooltip */}
              <div className="absolute right-0 mt-2 w-auto min-w-max invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 bg-white border border-[#eae7e6] rounded-lg shadow-lg p-3 z-50">
                <div className="flex items-center space-x-2 whitespace-nowrap">
                  <span className="text-[#8c7f7c]">{contactInfo.email}</span>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(contactInfo.email, 'email');
                    }}
                    className="text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 ml-2"
                  >
                    {copiedEmail ? <Check size={16} /> : <Copy size={16} />}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Phone Icon with Tooltip */}
          <div className="relative">
            <div className="p-2 group">
              <Phone 
                size={20} 
                className="text-[#8c7f7c] group-hover:text-[#b2a5a1] cursor-pointer"
              />
              {/* Phone Tooltip */}
              <div className="absolute right-0 mt-2 w-auto min-w-max invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 bg-white border border-[#eae7e6] rounded-lg shadow-lg p-3 z-50">
                <div className="flex items-center space-x-2 whitespace-nowrap">
                  <span className="text-[#8c7f7c]">{contactInfo.phone}</span>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(contactInfo.phone, 'phone');
                    }}
                    className="text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200 ml-2"
                  >
                    {copiedPhone ? <Check size={16} /> : <Copy size={16} />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile menu button */}
        <button 
          className="md:hidden relative group p-2"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <div className="flex flex-col space-y-1.5">
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? 'rotate-45 translate-y-2' : ''}`}></span>
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transition-all duration-200 ${isMenuOpen ? 'opacity-0' : ''}`}></span>
            <span className={`block w-6 h-0.5 bg-[#8c7f7c] transform transition-all duration-200 ${isMenuOpen ? '-rotate-45 -translate-y-2' : ''}`}></span>
          </div>
        </button>
      </div>
    </div>
  </div>

{/* Mobile Navigation */}
<div className={`md:hidden bg-white overflow-hidden transition-all duration-300 ${isMenuOpen ? 'max-h-screen' : 'max-h-0'}`}>
  <div className="px-4 py-3 space-y-2">
    {["Home", "About", "Services", "Properties", "Contact"].map((item) => (
      <div key={item}>
        {item === "Services" ? (
          <div>
            <button
              className={`w-full px-4 py-3 text-center hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 ${
                location.pathname === "/services" ? "text-[#b2a5a1] bg-[#f7f5f5]" : "text-[#8c7f7c]"
              }`}
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              <div className="flex items-center justify-center">
                Services
                <ChevronDown 
                  className={`ml-1 w-4 h-4 transform transition-transform duration-200 ${
                    openDropdown ? 'rotate-180' : ''
                  }`} 
                />
              </div>
            </button>
            
            {/* Mobile Services Dropdown */}
            <div className={`overflow-hidden transition-all duration-200 ${openDropdown ? 'max-h-96' : 'max-h-0'}`}>
              <div className="py-2 px-4 space-y-2">
                {[
                  { 
                    icon: Home, 
                    title: "Property Management",
                    sectionId: "property-management",
                    subItems: [
                      { name: "Residential Lettings", id: "residential-lettings" },
                      { name: "Short Term Luxury Lettings", id: "short-term-luxury-lettings" },
                      { name: "Portfolio Management", id: "portfolio-management" }
                    ]
                  },
                  { 
                    icon: Key, 
                    title: "Planning, Design & Build",
                    sectionId: "development",
                    subItems: [
                      { name: "Planning", id: "planning" },
                      { name: "Design and Build", id: "design-and-build" }
                    ]
                  }
                ].map((service, index) => (
                  <div key={index} className="space-y-2">
                    <button
                      onClick={() => {
                        navigate(`/services#${service.sectionId}`);
                        setIsMenuOpen(false);
                        setOpenDropdown(false);
                      }}
                      className="w-full flex items-center justify-between px-4 py-2 rounded-md hover:bg-[#f7f5f5] text-[#8c7f7c] hover:text-[#b2a5a1] transition-colors duration-200"
                    >
                      <div className="flex items-center space-x-3">
                        <service.icon className="w-5 h-5" />
                        <span className="font-medium">{service.title}</span>
                      </div>
                    </button>
                      
                    {/* Sub-items */}
                    <div className="pl-11 space-y-1">
                      {service.subItems.map((subItem, subIndex) => (
                        <button
                          key={subIndex}
                          onClick={() => {
                            navigate(`/services#${subItem.id}`);
                            setIsMenuOpen(false);
                            setOpenDropdown(false);
                          }}
                          className="w-full text-left py-2 px-4 text-[#8c7f7c] hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-md text-sm transition-colors duration-200"
                        >
                          {subItem.name}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          ) : (
            <Link
              to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
              className={`block px-4 py-3 text-center hover:text-[#b2a5a1] hover:bg-[#f7f5f5] rounded-lg transition-all duration-200 ${
                (item === "Home" && location.pathname === "/") || 
                (item !== "Home" && location.pathname === `/${item.toLowerCase()}`)
                  ? "text-[#b2a5a1] bg-[#f7f5f5]"
                  : "text-[#8c7f7c]"
              }`}
              onClick={() => setIsMenuOpen(false)}
            >
              <div className="flex items-center justify-center">
                {item}
              </div>
            </Link>
          )}
        </div>
      ))}
    </div>
  </div>
</header>

      {/* Main Content */}
      <div className="bg-gradient-to-br from-[#8c7f7c]/70 to-[#6a5f5c]/70">
        {/* Hero Section */}

{/* Our Process */}
<div id="process" className="py-24">
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="text-center mb-16">
      <h1 className="text-4xl font-bold text-white sm:text-5xl">
        A Glimpse Into Our Process
      </h1>
      <p className="mt-4 text-xl text-neutral-200 max-w-2xl mx-auto">
        We follow a structured approach to ensure we deliver the best property management solutions
      </p>
    </div>

    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
{/* Left side - Process explanation */}
<div className="bg-white/10 backdrop-blur-lg p-8 rounded-lg border border-white/20">
        <p className="text-neutral-200 mb-8">
          At Nova Asset Management, our consultation process is meticulously designed to maximise the potential of your property investments. We combine industry expertise with innovative solutions to deliver exceptional results through our proven four-step approach.
        </p>
        <div className="space-y-6">
          {[
            {
              title: "Portfolio Assessment",
              desc: "Comprehensive evaluation of your properties and investment goals",
              icon: Clipboard
            },
            {
              title: "Financial Analysis",
              desc: "Detailed market research and revenue optimisation strategies",
              icon: BarChart3
            },
            {
              title: "Strategy Development",
              desc: "Customised management plans tailored to your objectives",
              icon: Target
            },
            {
              title: "Implementation Plan",
              desc: "Clear timelines and milestones for seamless execution",
              icon: GitCommit
            }
          ].map((item, i) => (
            <div key={i} className="flex items-start space-x-4 p-4 rounded-lg hover:bg-white/5 transition-colors duration-200">
              <span className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-white/10 rounded-lg">
                <item.icon className="h-5 w-5 text-white" />
              </span>
              <div>
                <h4 className="text-white font-medium mb-1">{item.title}</h4>
                <p className="text-neutral-200 text-sm">{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Right side - Process steps */}
      <div className="bg-white/10 backdrop-blur-lg p-8 rounded-lg border border-white/20">
        <h3 className="text-2xl font-bold text-white mb-6">The Framework</h3>
        <div className="space-y-10">
          {[
            {
              step: "01",
              title: "Initial Consultation",
              desc: "We assess your property portfolio and understand your management needs"
            },
            {
              step: "02",
              title: "Custom Strategy",
              desc: "Develop a tailored management plan aligned with your objectives"
            },
            {
              step: "03",
              title: "Implementation",
              desc: "Seamless transition and setup of management services, ensuring all systems, processes, and stakeholders are aligned"
            },
            {
              step: "04",
              title: "Ongoing Support",
              desc: "Conducting regular reviews and continuous optimisation of services to adapt to your evolving needs as the market changes"
            }
          ].map((item, i) => (
            <div key={i} className="relative pl-14">
              <span className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-full bg-white/10 text-white font-bold">
                {item.step}
              </span>
              <div>
                <h4 className="text-lg font-semibold text-white">{item.title}</h4>
                <p className="mt-1 text-neutral-200">{item.desc}</p>
              </div>
              {i < 3 && (
                <div className="absolute left-5 top-10 h-full w-px bg-white/20" />
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Bottom CTA */}
      <div className="lg:col-span-2 mt-8 text-center">
        <Link
          to="/contact"
          className="inline-flex items-center px-8 py-3 bg-white text-[#8c7f7c] rounded-lg font-medium hover:bg-neutral-100 transition-colors duration-200 group"
        >
          Schedule a Consultation
          <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
        </Link>
      </div>
    </div>
  </div>
</div>   

{/* Our Story */}
<div id="overview" className="py-24 bg-white/5">
    <div className="max-w-7xl mx-auto px-4 sm:px-6">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-4xl font-bold text-white mb-6">Our Story</h2>
        <p className="text-lg text-neutral-200 mb-8">
          Nova Asset Management has rapidly emerged as a dynamic force in property asset management. Our journey focuses on optimising rental properties through a combination of long-term tenancies, short-term rentals, and luxury serviced lodgings. This targeted strategy ensures our clients receive the highest possible returns, establishing us as industry leaders.
        </p>
        <p className="text-lg text-neutral-200 mb-8">
          We are committed to supporting our clients by providing advance rent paid through direct debit on the first of every month, ensuring every investment is secured with a guaranteed income. With our assets under management growing rapidly and approaching significant milestones, we continuously improve our service offering to take advantage of future opportunities.
        </p>
        <div className="text-xl font-semibold text-white mb-8">
          "Infallible Commitment, Superior Results"
        </div>
      </div>

      {/* Stats Section */}
      <div className="mt-16 grid grid-cols-2 gap-8 md:grid-cols-4">
        {[
          { value: "50+", label: "Years Experience Combined", icon: Award },
          { value: "500+", label: "Properties Managed", icon: Building2 },
          { value: "£800M+", label: "Portfolio Value", icon: UserCheck },
          { value: "24/7", label: "Support Available", icon: Headset }
        ].map((stat, i) => (
          <div 
            key={i} 
            className="text-center transform hover:scale-105 transition-transform duration-200 animate-fade-in-up"
            style={{ animationDelay: `${i * 100}ms` }}
          >
            <div className="flex justify-center mb-4">
              <div className="p-3 bg-white/10 rounded-lg">
                <stat.icon className="h-6 w-6 text-white" />
              </div>
            </div>
            <div className="text-3xl font-bold text-white mb-2">{stat.value}</div>
            <div className="text-neutral-200">{stat.label}</div>
          </div>
        ))}
      </div>


      {/* Service Approach */}
      <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
        {[
          {
            title: "Long-term Tenancies",
            desc: "Stable, reliable income through carefully vetted long-term tenants"
          },
          {
            title: "Short-term Rentals",
            desc: "Maximising returns through strategic short-term letting opportunities"
          },
          {
            title: "Luxury Serviced Lodgings",
            desc: "Premium service for high-end properties and discerning clients"
          }
        ].map((service, i) => (
          <div key={i} className="bg-white/10 backdrop-blur-lg p-6 rounded-lg border border-white/20">
            <h3 className="text-xl font-semibold text-white mb-3">{service.title}</h3>
            <p className="text-neutral-200">{service.desc}</p>
          </div>
        ))}
      </div>
    </div>
  </div>   

{/* Why Choose Us */}
<div id="whyus" className="py-24 bg-white/5">
  <div className="max-w-7xl mx-auto px-4 sm:px-6">
    <div className="text-center mb-16">
      <h2 className="text-3xl font-bold text-white mb-4">Why Choose Nova Asset Management</h2>
      <p className="text-xl text-neutral-200 max-w-2xl mx-auto">
        Industry expertise combined with innovative solutions
      </p>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {[
        {
          title: "Industry Expertise",
          desc: "Over 50 years of combined experience in property asset management",
          icon: Building2
        },
        {
          title: "Financial Security",
          desc: "Guaranteed rental income with advance payments through direct debit",
          icon: PoundSterling
        },
        {
          title: "Innovative Technology",
          desc: "Advanced property management software and reporting tools",
          icon: Laptop
        },
        {
          title: "Dedicated Support",
          desc: "Round-the-clock property care and emergency response",
          icon: Headset
        },
        {
          title: "Transparent Communication",
          desc: "Regular updates and detailed performance reports",
          icon: MessageSquare
        },
        {
          title: "Tailored Solutions",
          desc: "Customised management strategies for your portfolio",
          icon: Settings
        }
      ].map((item, i) => (
        <div key={i} className="bg-white/10 backdrop-blur-lg p-6 rounded-lg border border-white/20">
          <div className="flex justify-center mb-4">
            <div className="p-3 bg-white/10 rounded-lg">
              <item.icon className="h-8 w-8 text-white" />
            </div>
          </div>
          <h3 className="text-xl font-semibold text-white text-center mb-2">
            {item.title}
          </h3>
          <p className="text-neutral-200 text-center">
            {item.desc}
          </p>
        </div>
      ))}
    </div>
  </div>
</div>

        {/* Values */}
        <div id="values" className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <h2 className="text-3xl font-bold text-white text-center mb-16">Our Values</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                {
                  icon: Target,
                  title: "Excellence",
                  desc: "Striving for the highest standards in everything we do"
                },
                {
                  icon: HandshakeIcon,
                  title: "Integrity",
                  desc: "Building trust through transparent and ethical practices"
                },
                {
                  icon: Sparkles,
                  title: "Innovation",
                  desc: "Embracing new technologies and solutions"
                },
                {
                  icon: Users,
                  title: "Partnership",
                  desc: "Working closely with clients to achieve their goals"
                }
              ].map((value, i) => (
                <div key={i} className="bg-white/10 backdrop-blur-lg p-6 rounded-lg border border-white/20">
                  <div className="flex justify-center mb-4">
                    <div className="p-3 bg-white/10 rounded-lg">
                      <value.icon className="h-8 w-8 text-white" />
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold text-white text-center mb-2">{value.title}</h3>
                  <p className="text-neutral-200 text-center">{value.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA */}
        <div id="contact" className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 text-center">
            <h2 className="text-3xl font-bold text-white mb-4">Ready to Work Together?</h2>
            <p className="text-xl text-neutral-200 mb-8">
              Let us help you achieve your property management goals
            </p>
            <Link
              to="/contact"
              className="inline-flex items-center px-8 py-3 bg-white text-[#8c7f7c] rounded-lg font-medium hover:bg-neutral-100 transition-colors duration-200 group"
            >
              Get in Touch
              <ArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        </div>
      </div>

{/* Contact */}
<div className="bg-[#2c2827]">
  <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
      {[
        {
          icon: Phone,
          text: "+44 (0)20 1234 5678",
          action: () => window.location.href = "tel:+442012345678"
        },
        {
          icon: Mail,
          text: "info@novaml.co.uk",
          action: () => window.location.href = "mailto:info@novaml.co.uk"
        },
        {
          icon: MapPin,
          text: "123 Property Street, London, UK",
          action: () => window.open("https://maps.google.com/?q=123+Property+Street+London+UK", "_blank")
        }
      ].map((item, i) => (
        <button
          key={i}
          onClick={item.action}
          className="flex items-center p-4 bg-[#3d3533] rounded-lg shadow-sm border border-[#4d4341] hover:border-[#b2a5a1] hover:shadow-md transition-all duration-200 group w-full"
        >
          <item.icon className="h-6 w-6 text-[#b2a5a1] group-hover:scale-110 transition-transform" />
          <span className="ml-3 text-[#968a87] group-hover:text-[#b2a5a1] transition-colors duration-200">
            {item.text}
          </span>
        </button>
      ))}
    </div>
  </div>
</div>

{/* Footer */}
<footer className="bg-[#2c2827] border-t border-[#3d3533]">
  <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
      {[
        { 
          title: "Company", 
          links: [
            { name: "Home", path: "/" },
            { name: "Services", path: "/services" },
            { name: "Properties", path: "/properties" },
            { name: "Contact", path: "/contact" }
          ]
        },
        { 
          title: "Services", 
          links: [
            { name: "Property Management", sectionId: "property-management" },
            { name: "Planning, Design & Build", sectionId: "development" }
          ]
        },
        { 
          title: "Legal", 
          links: [
            { name: "Privacy", path: "/Registration Certificate.pdf", type: "pdf" },
            { name: "Data Protection", path: "/Registration Certificate.pdf", type: "pdf" },
            { name: "Terms", path: "/terms" }
          ]
        },
        { 
          title: "Social", 
          links: [
            { name: "LinkedIn", path: "https://linkedin.com" },
            { name: "Twitter", path: "https://twitter.com" },
            { name: "Facebook", path: "https://facebook.com" }
          ]
        }
      ].map((section, i) => (
        <div key={i}>
          <h3 className="text-sm font-semibold text-[#b2a5a1] tracking-wider uppercase">
            {section.title}
          </h3>
          <ul className="mt-4 space-y-4">
            {section.links.map((link, j) => (
              <li key={j}>
                {section.title === "Social" ? (
                  <a 
                    href={link.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </a>
                ) : section.title === "Services" ? (
                  <button
                    onClick={() => navigate(`/services#${link.sectionId}`)}
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </button>
                ) : link.type === "pdf" ? (
                  <a
                    href={link.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </a>
                ) : (
                  <Link
                    to={link.path}
                    className="text-base text-[#968a87] hover:text-[#b2a5a1] transition-colors duration-200 flex items-center group"
                  >
                    <span>{link.name}</span>
                    <ArrowRight className="ml-2 opacity-0 group-hover:opacity-100 transform -translate-x-2 group-hover:translate-x-0 transition-all duration-200 text-[#b2a5a1]" size={14} />
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
    <div className="mt-8 border-t border-[#3d3533] pt-8">
      <p className="text-base text-[#968a87]">
        &copy; 2024 Nova Asset Management Ltd. All rights reserved.
      </p>
    </div>
  </div>
</footer>
    </div>
  );
};

export default AboutPage;